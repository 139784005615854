import { useState } from "react";
import emailjs from '@emailjs/browser';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Grid} from '@material-ui/core';
import tyler from './images/27008.jpeg';
import jake from './images/1676411790888.jpeg';
import mold from './images/mold.png';
import bridge from './images/bridge.jpeg';
import { Container, TextField} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    alignItems: 'center',
  },
  title: {
    flexGrow: 1,
    textAlign: 'left',
    paddingLeft: {md: theme.spacing(10), sm: theme.spacing(10)},
    paddingRight: {md: theme.spacing(10)},
  },
  nav: {
    paddingRight: {md: theme.spacing(10)},
    textAlign: 'right',
  },
  link: {
    textDecoration: 'none',
    color: '#fff',
    fontWeight: 'bold',
    marginLeft: theme.spacing(2),
  },
  hero: {
    backgroundImage: `url(${bridge})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    // opacity: 0.7,
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0.1, 0.1, 0.1, 0.1)', /* Change the color and opacity here */
    },
  },
  heroTitle: {
    color: '#fff',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textShadow: '1px 1px #000',
    // marginBottom: theme.spacing(3),
    marginTop: theme.spacing(25),
  },
  heroSubTitle: {
    color: '#fff',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textShadow: '1px 1px #000',
    marginBottom: theme.spacing(3),
  },
  heroButton: {
    backgroundColor: '#fff',
    color: '#000',
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
    '&:hover': {
      backgroundColor: '#000',
      color: '#fff',
    },
  },
  section: {
    padding: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
  },
  sectionInfo: {
    backgroundColor: '#f0f0f0',
    padding: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
  },
  sectionContact: {
    backgroundColor: '#f0f0f0',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    padding: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
  },
  sectionText: {
    flexGrow: 1,
    paddingRight: theme.spacing(6),
  },
  sectionTitle: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      fontSize: 40
    }
  },
  sectionImage: {
    width: '50%',
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  sectionImageTwo: {
    width: '50%',
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      width: '80%',
      marginLeft: theme.spacing(0),
    }
  },
  inventorImage: {
    height: '80%',
    width: '80%',
  },
  inventorCard: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '20px',
  },
  inventorCardContent: {
    marginLeft: '20px',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '400px',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  form: {
    maxWidth: '500px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  footer: {
    backgroundColor: '#000',
    color: '#fff',
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  copyright: {
    textAlign: 'center',
  },
}));

function App() {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    var templateParams = {
      from_name: name,
      from_email: email,
      message: message
    };
    emailjs.init('5N0Xi3bRNzjiMu-4m');

    emailjs.send('service_s8soudq', 'template_s4wkinr', templateParams)
    .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
    }, function(error) {
       console.log('FAILED...', error);
    });

    // reset form fields
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div className={classes.root}>
      <AppBar position="sticky" style={{ background: '#2E3B55' }}>
        <Toolbar>
          <Typography variant="h5" className={classes.title}>
            Concrete TAG
          </Typography>
          <nav className={classes.nav}>
            <a href="#info" className={classes.link}>INFO</a>
            <a href="#about" className={classes.link}>ABOUT</a>
            <a href="#contact" className={classes.link}>CONTACT</a>
          </nav>
        </Toolbar>
      </AppBar>
      <div className={classes.hero}>
        <Typography variant="h1" className={classes.heroTitle}>
          WELCOME TO CONCRETE TAG
        </Typography>
        <Typography variant="caption" className={classes.heroSubTitle}>
          Commercializing a novel way to custom label and track concrete elements
        </Typography>
        <Button variant="contained" className={classes.heroButton} href="#info">
          Learn more
        </Button>
      </div>
      <section id="info" className={classes.sectionInfo}>
        <div className={classes.sectionText}>
          <Typography variant="h2" className={classes.sectionTitle}>
            What exactly is CTAG?
          </Typography>
          <Typography variant="body1">
            The CTAG (concrete tag) barcode provides a matching label on the surface of the mold and
            also on the surface of the concrete. The CTAG uses a fabric-like surface that is embedded in the
            fresh concrete to permanently attach the barcode to the surface of the concrete sample. A
            matching barcode is used on the surface of the mold. These barcodes can be scanned with the
            CTAG app to identify the sample, add measurements, and track the chain of custody of the
            sample and to send the results to AASHTO WARE or any other database.
            The CTAG is useful because managing hardened concrete samples can be challenging. These
            samples must be labeled during creation, transported to a testing lab, demolded, labeled again,
            tested at the correct date, and then the data must be recorded, and finally a report must be created
            and uploaded. This process is time consuming and is prone to errors from lost samples or
            mislabeled samples.
          </Typography>
        </div>
        <div className={classes.sectionImage}>
          {/* <img src={`https://www.sp-samson.com/media/k2/items/cache/d4b22e8d4b1fb6349c7d95bc5629e3c0_M.jpg`} alt="Concrete Tag Product" /> */}
          <img className='sideImg' src={mold} alt="Concrete Tag Product" style={{width: '100%'}}/>
        </div>
      </section>
      <section id="about" className={classes.section}>
        <div className={classes.sectionText}>
          <Typography variant="h2" gutterBottom className={classes.sectionTitle}>
            Behind the Innovation
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {/* <Paper className={classes.paper}> */}
                <div className={classes.sectionImageTwo}>
                  <img src={tyler} className={classes.inventorImage} alt='Tyler Ley'/>
                </div>
              {/* </Paper> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h4" gutterBottom>
                Tyler Ley
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Dr. Ley has more than 20 years of experience in the fields of structural and concrete materials engineering. His experience includes working as an engineer with a design consultant, construction contractor, government agency, and as a professor. He is an Associate Professor of Civil Engineering at Oklahoma State University where he was named the outstanding professor at a research university by the Oklahoma Foundation of Excellence in 2018.
                Dr. Ley was recognized as one of the Most Influential People in the Concrete Industry by Concrete Construction Magazine in 2019. He is active in the American Concrete Institute and is a voting member of the Concrete Durability, Material Science, Concrete
                Proportioning, and Sustainability Technical committees. He also serves on the executive committee of the National Concrete Consortium, a group made up of DOT engineers from 35 different states. His websites are visited by over 50,000 people per year and his education content on YouTube has been watched over 6 million times.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <Paper className={classes.paper}> */}
                <div className={classes.sectionImageTwo}>
                  <img src={jake} className={classes.inventorImage} alt='Jake Leflore'/>
                </div>
              {/* </Paper> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h4" gutterBottom>
                Jake Leflore
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
              Jake LeFlore is the Co-inventor, Operations Manager, and founding partner of the Concrete Tag. He graduated from Oklahoma State University in 2006 with a bachelor's degree in Business Management. He has a wealth of experience in concrete materials and testing, transportation construction and maintenance, electrical work, commercial and residential construction, business operations, and distribution networks. 
              </Typography>
            </Grid>
          </Grid>
        </div>
      </section>
      <section id="contact" className={classes.sectionContact}>
        <div className={classes.sectionText}>
          <Typography align='center' variant="h4" gutterBottom className={classes.sectionTitle}>
            Want to know more about CTAG? Connect with us
          </Typography>
        <Container maxWidth="md">
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Name"
              margin="normal"
              className={classes.textField}
              type='text'
              placeholder="Name"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Email"
              margin="normal"
              className={classes.textField}
              type='email'
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Message"
              margin="normal"
              multiline
              rows={4}
              type='text'
              className={classes.textField}
              placeholder="Message"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitButton}
            >
              Submit
            </Button>
          </form>
        </Container>
        </div>
    </section>
    <footer className={classes.footer}>
      <Container>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.copyright}>
              &copy; 2023 Concrete Tag. All rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
    </div>
  );
}

export default App;
